<template>
    <div class="plans">
        <div class="block"></div>

        <div class="container">
            <div class="grid plans-layout">
                <aside class="grid-item plans-sidebar">
                    <h1 class="page-title">{{ $t("title.compatibility") }}</h1>
                </aside>
                <div class="grid-item plans-body">
                    <p
                        class="ts-lead ts-quiet ts-measure"
                        v-html="$t('pages.compatibility.top_text')"
                    ></p>
                </div>
            </div>
        </div>

        <div class="block-md"></div>
        <div class="container">
            <div class="grid plans-layout">
                <aside class="grid-item plans-sidebar">
                    <div class="to-affix">
                        <div class="block-lg">
                            <h3>{{ $t("title.categories") }}</h3>
                            <ul class="plans-sidebar-nav">
                                <li v-for="device in devices" :key="`links_${device}`">
                                    <router-link :to="`#${device.toLowerCase()}`">{{
                                        device
                                    }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </aside>
                <div class="grid-item plans-body next-to-affix">
                    <CompatibilityBlock
                        v-for="device in devices"
                        :category="device"
                        :devices="getDevices(device.toLowerCase())"
                        :key="device"
                    />
                </div>
            </div>
        </div>
        <div class="block-xl"></div>
    </div>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import CompatibilityBlock from "@/components/CompatibilityBlock"

import ios from "@/assets/data/compatibility/en/ios"
import android from "@/assets/data/compatibility/en/android"
import others from "@/assets/data/compatibility/en/others"
import iosJa from "@/assets/data/compatibility/ja/ios"
import androidJa from "@/assets/data/compatibility/ja/android"
import othersJa from "@/assets/data/compatibility/ja/others"

export default {
    components: { CompatibilityBlock },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.compatibility.title"),
            description: this.$i18n.t("meta.compatibility.description"),
        })
    },
    data() {
        return {
            showCoverage: {},
            showNetwork: {},
            devices: ["iOS", "Android"],
        }
    },
    computed: {
        regions() {
            return this.plans.filter(p => p.regions)
        },
        countries() {
            return this.plans.filter(p => !p.regions)
        },
        ...mapGetters({ plans: "data/plans" }),
    },
    methods: {
        getDevices(category) {
            if (this.$i18n.locale === "en") {
                switch (category) {
                    case "ios":
                        return ios
                    case "android":
                        return android
                    case "others":
                        return others
                    default:
                        return []
                }
            }
            if (this.$i18n.locale === "ja") {
                switch (category) {
                    case "ios":
                        return iosJa
                    case "android":
                        return androidJa
                    case "others":
                        return othersJa
                    default:
                        return []
                }
            }

            return []
        },
        toggleCoverageRegion(region) {
            if (this.showCoverage[region.id]) {
                return Vue.delete(this.showCoverage, region.id)
            }
            return Vue.set(this.showCoverage, region.id, true)
        },
        toggleCoverageCountry(county) {
            if (this.showNetwork[county.id]) {
                return Vue.delete(this.showNetwork, county.id)
            }
            return Vue.set(this.showNetwork, county.id, true)
        },
        changeSelect(value) {
            if (value.target.value) {
                const [type, id] = value.target.value.split(".")
                if (type == "region") {
                    this.$router.replace({ name: this.$route.name, hash: `#region-${id}` })
                } else {
                    this.$router.replace({
                        name: this.$route.name,
                        hash: `#country-${id.toLowerCase()}`,
                    })
                }
            }
        },
    },
}
</script>
