<template>
    <div class="block-xl" :id="category.toLowerCase()">
        <h2>{{ category }}</h2>
        <div class="block-md">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-head">
                        <h3 class="panel-title">{{ $t("label.devices") }}</h3>
                    </div>
                </div>

                <div class="panel-body">
                    <div class="list-group">
                        <div class="list-group-item">
                            <div class="plans-row">
                                <div class="plans-row-body ts-quiet">
                                    <div class="plans-row-item name">
                                        <span>{{ $t("label.manufacturer") }}</span>
                                    </div>
                                    <div class="plans-row-item product">
                                        <span>{{ $t("label.product") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="list-group-item"
                            v-for="item in devices.devices"
                            :key="`${item.manufacturer}-${item.product}`"
                        >
                            <div class="plans-row">
                                <div class="plans-row-body ts-strong">
                                    <div class="plans-row-item name">
                                        <span>{{ item.manufacturer }}</span>
                                    </div>
                                    <div class="plans-row-item product">
                                        <span>{{ item.product }}</span>
                                        <span class="note ts-quiet" v-if="item.product_note">{{
                                            item.product_note
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        category: { type: String, required: true },
        devices: { required: true },
    },
    computed: {},
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        },
    },
}
</script>

<style scoped>
.plans-row-item.product {
    flex: 0 0 66%;
    text-align: left;
}
.panel .list-group-item {
    padding: 15px 4%;
}
.plans-row-item.name {
    flex: 0 0 20%;
}
@media (max-width: 400px) {
    .plans-row-item.name {
        text-align: right;
        padding-right: 15px;
        flex: 0 0 33%;
    }
    .panel .list-group-item {
        padding: 15px 0;
    }

    #android {
        margin-top: 4em;
    }
}
</style>
